.rc-time-picker {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
}
.rc-time-picker * {
  box-sizing: border-box;
}
.rc-time-picker-input {
  height: auto;
  font-family: ProximaNova-Regular, sans-serif;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  position: relative;
  display: block;
  padding: 8px 12px 8px 39px;
  cursor: text;
  color: #303F47;
  background-color: transparent;
  background-image: none;
  border: 1px solid #C1CACE;
  border-radius: 4px;
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.rc-time-picker-input[disabled] {
  color: #AFBDC5;
  background-color: #F8FAFB;
}
.rc-time-picker-panel {
  z-index: 9999;
  width: 160px;
  position: absolute;
  box-sizing: border-box;
}
.rc-time-picker-panel * {
  box-sizing: border-box;
}
.rc-time-picker-panel-inner {
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 12px;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px #D6DADC;
  background-clip: padding-box;
  border: 1px solid #D6DADC;
  line-height: 1.5;
  width: 100%;
  margin-top: 42px;
}
.rc-time-picker-panel-narrow {
  max-width: 113px;
}
.rc-time-picker-panel-input-wrap {
  display: none;
}
.rc-time-picker-panel-input-invalid {
  border-color: red;
}
.rc-time-picker-panel-clear-btn {
  position: absolute;
  right: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 6px;
  margin: 0;
}
.rc-time-picker-panel-clear-btn:after {
  content: "x";
  font-size: 12px;
  color: #aaa;
  display: inline-block;
  line-height: 1;
  width: 20px;
  transition: color 0.3s ease;
}
.rc-time-picker-panel-clear-btn:hover:after {
  color: #666;
}
.rc-time-picker-panel-select {
  font-family: ProximaNova-Regular, sans-serif;
  font-size: 14px;
  line-height: 17px;
  float: left;
  border: 1px solid #D6DADC;
  border-width: 0 1px;
  box-sizing: border-box;
  width: 56px;
  max-height: 144px;
  overflow-y: auto;
  position: relative;
  margin: 5px 0;
  margin-left: -1px;
}

.rc-time-picker-panel-select::-webkit-scrollbar {
  width: 6px;
}

.rc-time-picker-panel-select::-webkit-scrollbar-track {
  background: transparent;
}

.rc-time-picker-panel-select::-webkit-scrollbar-thumb {
  border-radius: 4.5px;
  background-color: #1E87F0;
}

.rc-time-picker-panel-select-active {
  overflow-y: auto;
}
.rc-time-picker-panel-select:first-child {
  border-left: 0;
  margin-left: 0;
}
.rc-time-picker-panel-select:last-child {
  border-right: 0;
  width: 46px;
}
.rc-time-picker-panel-select ul {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.rc-time-picker-panel-select li {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  box-sizing: border-box;
  border: 1px solid #D6DADC;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  line-height: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 0 5px;
  text-transform: uppercase;
}
.rc-time-picker-panel-select li:hover {
  border: none;
  background-color: rgba(0, 0, 0, 0.04);
}
li.rc-time-picker-panel-select-option-selected {
  color: #fff;
  background-color: #1E87F0;
  font-family: ProximaNova-Semibold, sans-serif;
  border: none;
}
li.rc-time-picker-panel-select-option-selected:hover {
  color: #fff;
  background-color: #1E87F0;
  font-family: ProximaNova-Semibold, sans-serif;
  border: none;
}
li.rc-time-picker-panel-select-option-disabled {
  color: #ccc;
}
li.rc-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
