body {
  margin: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  margin-top: 0;
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('../assets/fonts/ProximaNova-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('../assets/fonts/ProximaNova-Bold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Light';
  src: url('../assets/fonts/ProximaNova-Light.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-RegularIt';
  src: url('../assets/fonts/ProximaNova-RegularIt.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Semibold';
  src: url('../assets/fonts/ProximaNova-Semibold.otf');
  font-weight: normal;
  font-style: normal;
}

.Toastify__toast--error {
  background: #E2231A !important;
}

.Toastify__toast--success {
  background: #AAD26F !important;
}

.recharts-cartesian-axis { font-family: 'ProximaNova-Regular'; }

@-webkit-keyframes glowing {
  0% { background-color: #1ec7f0; -webkit-box-shadow: 0 0 3px #22a7f0; }
  50% { background-color: #239ff0; -webkit-box-shadow: 0 0 20px #0a8cf0; }
  100% { background-color: #1E87F0; -webkit-box-shadow: 0 0 3px #22a7f0; }
}

@-moz-keyframes glowing {
  0% { background-color: #1ec7f0; -moz-box-shadow: 0 0 3px #22a7f0; }
  50% { background-color: #239ff0; -moz-box-shadow: 0 0 20px #0a8cf0; }
  100% { background-color: #1E87F0; -moz-box-shadow: 0 0 3px #22a7f0; }
}

@-o-keyframes glowing {
  0% { background-color: #1ec7f0; box-shadow: 0 0 3px #22a7f0; }
  50% { background-color: #239ff0; box-shadow: 0 0 20px #0a8cf0; }
  100% { background-color: #1E87F0; box-shadow: 0 0 3px #22a7f0; }
}

@keyframes glowing {
  0% { background-color: #1ec7f0; box-shadow: 0 0 3px #22a7f0; }
  50% { background-color: #239ff0; box-shadow: 0 0 20px #0a8cf0; }
  100% { background-color: #1E87F0; box-shadow: 0 0 3px #22a7f0; }
}

@-webkit-keyframes pulse-ring {
  0% {
    transform: scale(0);
    opacity: 1;
  }


  100%{
    transform: scale(1);
    opacity: 0;
  }
}
@-moz-keyframes pulse-ring {
  0% {
    transform: scale(0);
    opacity: 1;
  }


  100%{
    transform: scale(1);
    opacity: 0;
  }
}
@-o-keyframes pulse-ring {
  0% {
    transform: scale(0);
    opacity: 1;
  }


  100%{
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0);
    opacity: 1;
  }


  100%{
    transform: scale(1);
    opacity: 0;
  }
}
